<template>
<div>
    <img src="../../../public/bg.jpg" id="bg" alt="">

    <div>
            <CAlert
                color="danger"
                :show.sync="checkError"
                class="alert-dismissible"
            >
                {{ this.errorMessage }}
                
            </CAlert>
    </div>

    <div class="c-app flex-row align-items-center">
        <CContainer>
            <CRow class="justify-content-center">
                <CCol md="6">
                    <CCardGroup>
                        <CCard class="p-4">
                            <img
                                src="../../../public/logo/logobg.png"
                                style="width: 50% !important"
                                class="image-logo"
                            />

                            <CCardBody>
                                <CForm novalidate v-on:keyup.enter="onSubmit">
                                    <div>
                                        <p v-if="errors.length">
                                        <b>Please correct the following error(s):</b>
                                        <ul>
                                        <li :key="key" v-for="(error, key) in errors">{{ error }}</li>
                                        </ul>
                                    </p>
                                    </div>
                                    <h1>Login</h1>
                                    
                                    <CInput
                                        placeholder="Email"
                                        autocomplete="Username email"
                                        required
                                        was-validated
                                        v-model="model.email"
                                        
                                    >
                                        <template #prepend-content
                                            ><CIcon name="cil-user"
                                        /></template>
                                    </CInput>

                                    <CInput
                                        id="password"
                                        placeholder="Password"
                                        type="password"
                                        autocomplete="curent-password"
                                        required
                                        was-validated
                                        v-model="model.password"
                                    > 
                                        <!-- <template #prepend-content>
                                            <CIcon name="cil-lock-locked"/>
                                        </template> -->

                                        <template #append>
                                        <CButton size="sm" class="ml-1" color="secondary" @click="showPassword()">
                                            <div v-if="!flag">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-slash" viewBox="0 0 16 16">
                                                    <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z"/>
                                                    <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z"/>
                                                    <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z"/>
                                                </svg>
                                                
                                                <!-- <CIcon size="sm" name="cil-lock-locked"/> -->
                                            </div>
                                            <div v-if="flag">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
                                                    <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                                                    <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                                                </svg>
                                                <!-- <CIcon size="sm" name="cil-lock-unlocked"/> -->
                                            </div>
                                        </CButton>
                                    </template>

        
                                
                                    </CInput>

                                    <CRow>
                                        <CCol col="7" class="text-left">
                                            <CButton
                                                color="primary"
                                                native-type="submit"
                                                class="px-4"
                                                @click="onSubmit"
                                                >Login</CButton
                                            >
                                        </CCol>

                                        <CCol col="5" class="text-right">
                                            <CButton color="link" class="px-0"
                                                >Forgot password</CButton
                                            >
                                        </CCol>
                                    </CRow>
                                </CForm>
                            </CCardBody>
                        </CCard>
                    </CCardGroup>
                </CCol>
            </CRow>
        </CContainer>

        
        <div>
            <CModal title="Errors" :show.sync="myModal" size="sm">
            </CModal>
        </div>
    </div>
</div>
    


</template>

<script>
import axios from "axios";
import jwt_decode from "jwt-decode";
import VuePassword from 'vue-password';

const URL = axios.defaults.baseURL;
const loginUrl = URL + `${"profile/login/"}`;

export default {
    name: "Login",
    components: {
        VuePassword
    },
    data() {
        return {
            errorMessage: "",
            checkError: false,
            errors: [],
            myModal: false,
            isPressed: true,
            message: "",
            model: {
                email: null,
                password: null,
                rememberMe: false,
            },
            flag: 0,
        };
    },
  
    methods: {
        showPassword(){
            const passwordField = document.querySelector('#password');
            if(this.flag==0)
                this.flag=1;
            else
                this.flag=0;
             
             if (passwordField.getAttribute('type') === 'password') passwordField.setAttribute('type', 'text')
                else passwordField.setAttribute('type', 'password')
        },

        onSubmit() {
            //Set flag to hide button and show loader
            this.isPressed = true;
            this.axios
                .post(loginUrl, {
                    email: this.model.email,
                    password: this.model.password,
                })
                .then((response) => {
                    var role = jwt_decode(response.data.access_token).role;
                    console.log(response.data.message);
                    localStorage.setItem("token", response.data.access_token);
                    // this.$store.commit("token", response.data.access_token);
                    localStorage.setItem("userRole", role);
                    if (role == "admin") this.$router.push("/adminDashboard");
                    if (role == "farmer") this.$router.push("/farmerDashboard");
                    if (role == "bank") this.$router.push("/bankDashboard");
                })
                .catch((error) => {
                    this.checkError = true;
                    try {
                        this.errorMessage = error.response.data.message;
                        // console.log(error.response.data.message);
                    }
                    catch {
                        this.errorMessage = error.message;
                    }
                });
        },
        closeModal() {
            setTimeout(() => {
                this.$refs.modal.hide();
            }, 2000);
        },
        openModal() {
            this.myModal = true;
        },
    },

    watch:{
        checkError(newValue){
            if (newValue)
              setTimeout(() => this.checkError = false, 2000)  
        }
    },
};
</script>

<style>
#bg {
  position: fixed; 
  top: 0; 
  left: 0; 
	
  /* Preserve aspet ratio */
  min-width: 100%;
  min-height: 100%;

  opacity: 0.7;
}

.image-logo {
    align-self: center;
}
@supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */
    .image-logo {
        padding-left: 0rem !important;
        padding-right: 0rem !important;
    }
}
@supports not (-webkit-touch-callout: none) {
    /* CSS for other than iOS devices */
    .image-logo {
        padding-left: 3rem !important;
        padding-right: 3rem !important;
        padding-top: 1rem !important;
        padding-bottom: 0.5rem !important;
    }
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
    /* CSS specific to iOS devices */
    .image-logo {
        padding-left: 0rem !important;
        padding-right: 0rem !important;
        padding-top: 0rem !important;
        padding-bottom: 0rem !important;
    }
}
</style>